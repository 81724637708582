export default {
  about_contactUs: {
    templateUrl: './mtr.contact-us.component.html',
    styleUrls: ['./mtr.contact-us.component.scss'],
  },
  about_privacy: {
    templateUrl: './mtr.privacy.component.html',
  },
  about_termsOfUse: {
    templateUrl: './mtr.terms-of-use.component.html',
  },
};
